import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import Project from "./Project";

async function test() {
    let request = await axios.get('https://api.github.com/users/stibreant/repos')
    return (request.data)
}

const Projects = () => {
    const [projects, setProjects] = useState<any[]>([])
    const element1Ref = useRef<HTMLDivElement>(null);
    const element2Ref = useRef<HTMLDivElement>(null);
    const element3Ref = useRef<HTMLDivElement>(null);

    const Refs = [element1Ref, element2Ref, element3Ref];


    useEffect(() => {
        test().then((d) => setProjects(d))
    }, [])

    useEffect(() => {
        if (element1Ref.current != null) {
            // Set up the IntersectionObserver
            const observer = new IntersectionObserver((entries) => {
                // Loop through each intersecting element
                let timeBetween = 250
                let currentTime = 0
                for (const entry of entries) {
                    // Check if the element is intersecting with the viewport
                    if (entry.isIntersecting) {
                        // If it is, add the "is-visible" class to the element
                        setTimeout(() => { entry.target.classList.add('is-visible'); }, currentTime);
                        currentTime += timeBetween;

                        // Then disconnect the observer for that element so that it only runs once
                        observer.unobserve(entry.target);
                    }
                }
            });

            // Start observing each element
            observer.observe(element1Ref.current!);
            observer.observe(element2Ref.current!);
            observer.observe(element3Ref.current!);

            // Clean up the observer when the component unmounts
            return () => {
                observer.disconnect();
            }
        };
    }, [projects]); // This empty array ensures that the effect only runs once


    return (
        <Box marginTop={5}>
            <Typography variant="h4">Recent Projects:</Typography>
            {projects.length !==  0? 
            <Grid sx={{ marginTop: { xs: 2, md: 5 }}} container minHeight="80px">
                {projects.map((p, i) =>
                    <Grid key={i} ref={Refs[i]} className="fade-in" xs={12} md={4} paddingX={1} sx={{ marginTop: { xs: 2, md: 0 } }} item>
                        <Project project={p} />
                    </Grid>
                )}
            </Grid>
            :
            <Box marginTop={1}><CircularProgress color="secondary"/></Box>
            }
        </Box>
    )
};

export default Projects;