import { Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { Player } from "./Player";

interface ScoreboardProps {
    players: Player[];
}

const Scoreboard = (props: ScoreboardProps) => {
    const [sortedPlayer, setSortedPlayers] = useState(props.players)

    useEffect(() => {
        let newPlayers = props.players;
        newPlayers.sort((a, b) => (b.points + b.tmpPoints) - (a.points + a.tmpPoints));
        setSortedPlayers(newPlayers)
    }
    , [props.players])

    return <>
        <Typography>This is a Scoreboard</Typography>
        {sortedPlayer.map((player, i) =>
        <Typography key={i}>{`${player.name} : ${player.points + player.tmpPoints}`}</Typography>
        )}
    </>
}

export default Scoreboard;