import { Typography, Button, Card } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import Match from "./Match";
import { Player } from "./Player";
import { RoundGenerator } from "./RoundGenerator";

interface CurrentRoundProps {
    players: Player[],
    updateTmpScore: (match: [Player, Player], result: number | null) => void,
}

const CurrentRound = (props: CurrentRoundProps) => {
    const [matches, setMatches] = useState<[Player, Player][]>([]);
    const [results, setResults] = useState<(number | null)[]>([]);
    let roundGenerator = useMemo(() => new RoundGenerator(props.players), [props.players]);
    
    useEffect(() => {
        let newMatches = roundGenerator.randomMatches()
        setMatches(newMatches)
        setResults(new Array(newMatches.length).fill(null))
    }, [roundGenerator])

    const newRound = () => {
        let newMatches = roundGenerator.nextRound()
        setMatches(newMatches)
        setResults(new Array(newMatches.length).fill(null))
    }

    const updateResult = (result: number | null, index: number) => {
        let newResults = [...results];
        newResults[index] = result;
        setResults(newResults)
        props.updateTmpScore(matches[index], newResults[index])
    }

    return <Card>
        <Typography variant="h4" sx={{marginBottom: 1}}>Current Round</Typography>

        {matches.map((match, i) =>
            <Match key={i} match={match} index={i} result={results[i]} updateResult={updateResult}/>
        )}

        <Button variant="contained" onClick={newRound}>Generate Round</Button>

    </Card>
}

export default CurrentRound;