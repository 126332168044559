import Scoreboard from "./Scoreboard";
import Grid2 from '@mui/material/Unstable_Grid2';
import CurrentRound from "./CurrentRound";
import { Player } from "./Player";
import { useEffect, useState } from "react";

interface TournamentProps {
    players: Player[],
}

const Tournament = (props: TournamentProps) => {
    const [players,] = useState(props.players)
    const [playersMap, setPlayersMap] = useState<Map<string, Player>>(new Map<string, Player>())
    const [tempPlayers, setTempPlayers] = useState<Player[]>(players)

    useEffect(() => {
        let newPlayerMap = playersMap;

        players.map((p) => (newPlayerMap.set(p.name, p)))

        setPlayersMap(newPlayerMap);
    }
        , [players, playersMap])

    const updateTmpScore = (match: [Player, Player], result: number | null) => {
        let newPlayersMap = playersMap
        newPlayersMap.get(match[0].name)!.points = 0;
        newPlayersMap.get(match[1].name)!.points = 0;

        if (result !== null) {
            if (result === 0) {
                newPlayersMap.get(match[0].name)!.points = 0.5;
                newPlayersMap.get(match[1].name)!.points = 0.5;
            }
            else {
                let winner = match[0]
                if (result === -1) {
                    winner = match[1]
                }
                newPlayersMap.get(winner.name)!.points = 1;
            }
        }

        let newTempPlayers = Array.from(newPlayersMap.values())
        setTempPlayers(newTempPlayers)
    }

    // const NewRound = () => {
    //     // Check all tmp score != 0

    //     let newPlayers = tempPlayers
    //     for (let i = 0; i < newPlayers.length; i++) {
    //         newPlayers[i].points += newPlayers[i].tmpPoints;
    //         newPlayers[i].tmpPoints = 0;
    //     }
    // }

    return <>
        <Grid2 container>
            <Grid2 md={8} mdOffset={"auto"}>
                <CurrentRound players={players} updateTmpScore={updateTmpScore} />
            </Grid2>
            <Grid2 md={2} mdOffset={"auto"}>
                <Scoreboard players={tempPlayers} />
            </Grid2>
        </Grid2>
    </>
}

export default Tournament;