

export class Player{
    name:string; 
    points:number;
    tmpPoints:number;
    whiteGames:number;
 
   //constructor 
   constructor(name:string) { 
      this.name = name; 
      this.points = 0;
      this.tmpPoints = 0;
      this.whiteGames = 0;
   }  

   //function 
   disp():string { 
      return("Name is  :   "+ this.name);
   } 
}