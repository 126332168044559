import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import './App.css';
import Footer from './Components/Footer';
import NavBar from './Components/NavBar';
import MainPage from './Pages/MainPage';
import ProjectPage from './Pages/ProjectPage';
import TournamentPage from './Pages/TournamentPage';
import { Divider } from '@mui/material';
import AboutPage from './Pages/AboutPage';

// #F7006D
// #BF307F
const PRIMARY = "#151226"
const SECONDARY = '#BF307F'

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: PRIMARY,
    },
    secondary: {
      // main: '#f50057',
      main: SECONDARY,
    },
    background: {
      default: PRIMARY,
      paper: PRIMARY,
    },
    text: {
      primary: '#efefef',
      secondary: SECONDARY,
    },
  },
});

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>

        <Router basename='/'>
          <NavBar />
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/Projects" element={<ProjectPage />} />
            {/* <Route path="/About" element={<AboutPage />} /> */}
            <Route path="/About" element={<AboutPage/>} />
            <Route path="/Tournament" element={<TournamentPage />} />

            <Route path="*" element={<div id='main'><Typography>This page does not exist</Typography></div>} />
          </Routes>
          <Footer />
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
