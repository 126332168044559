import { Box, Divider, Typography } from "@mui/material"
import Experience, { ExperienceType } from "./Experience"

const work: ExperienceType[] = [
    {
        name: "Stavanger Studentsjakk",
        projectTitle: "Leader / Founder",
        date: new Date(2021, 3, 1),
        endDate: new Date(2022, 6, 1),
        description: <>During my time in school, I developed a keen interest in chess along with some friends. In the spring of 2021, we decided to breathe new life into the Stavanger Studentsjakk chess club, which had been inactive for years. I stepped up as the leader, taking charge of administrative tasks like club registration and ensuring everyone pitched in for event planning. Through our efforts, we secured the necessary funding for equipment, and I'm proud to say that the club is still thriving today!</>,
        image: "studentsjakk.png",
        imageBorderRadius: 100,
        imageMaxDimensions: {width: 175, height: 200},
        html_url: "https://www.facebook.com/stavangerstudentsjakk/",
    },
]

export const Voluntary = () => {
    return <Box marginTop={5}>
        <Typography variant="h4">Voluntary Work:</Typography>
        {work.map((e, i) => {
                    return <>
                        <Experience key={i} project={e} expanded={true}/>
                        {i != work.length - 1 ? <Divider sx={{ bgcolor: "secondary.light" }} light={false} /> : <></>}
                    </>
                })
                }
    </Box>
}