import { Box } from "@mui/material";
import Grid2 from '@mui/material/Unstable_Grid2';

const NavBar = () => {
    return(
        // color={"#efefef"} bgcolor={"Black"}
        <Box id={"navbar"} width={"100%"} minHeight={"3em"}  className="primary">
            <Grid2 container spacing={2} margin={"auto"} justifyContent={"center"}>
                {/* @ts-ignore */}
                <Grid2 xs={4} sx={{cursor: "pointer"}} onClick={() => {window.location = "/#/"}}>
                    Home
                </Grid2>
                {/* @ts-ignore */}
                <Grid2 xs={4} md={1} mdOffset="auto" sx={{cursor: "pointer"}} onClick={() => {window.location = "/#/About"}}>
                    About
                </Grid2>
                {/* @ts-ignore */}
                <Grid2 xs={4} md={1} textAlign={"center"} sx={{cursor: "pointer"}} onClick={() => {window.location = "/#/Projects"}}>
                    Projects
                </Grid2>
            </Grid2>
        </Box>
    )
}

export default NavBar;