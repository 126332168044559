import CloseIcon from '@mui/icons-material/Close';
import { Button, TextField, Typography } from "@mui/material";
import Grid2 from '@mui/material/Unstable_Grid2';
import { useState } from "react";
import { Player } from "./Player";

interface generatePlayerProps {
    updatePlayers: (players: Player[]) => void,
    updateGameState: (state: string) => void,
}

const GeneratePlayers = (props: generatePlayerProps) => {
    const [newPlayers, setNewPlayers] = useState<Player[]>([new Player("")])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, playerIndex: number) => {
        let players = [...newPlayers];
        players[playerIndex].name = event.target.value
        setNewPlayers(players);
    };

    const keyPress = (event: any, playerIndex: number) => {
        if (event.keyCode === 13 && newPlayers[playerIndex].name !== "") {
            setNewPlayers([...newPlayers, new Player("")])
        }
    }

    const removePlayer = (playerIndex: number) => {
        let players = [...newPlayers];
        players.splice(playerIndex, 1);
        setNewPlayers(players)
    }

    const start = () => {
        if (newPlayers.length > 2){
            console.log("Start Tournament");
            props.updateGameState("start");
            props.updatePlayers(newPlayers);
        }
    }

    return <>
        <Grid2 container>
            <Grid2 md={4} mdOffset="auto" margin={1}>
                <Typography>Input Players:</Typography>
                {newPlayers.map((player, i) => (
                    <div key={i}>
                        <TextField
                            id="outlined-name"
                            label="Name"
                            value={player.name}
                            onChange={(event) => handleChange(event, i)}
                            onKeyDown={(event) => keyPress(event, i)}
                            margin={"dense"}
                        />
                        {newPlayers.length !== 1? 
                        <CloseIcon onClick={() => removePlayer(i)} />
                        :
                        <></>
                        }
                    </div>
                )
                )}
                <Button variant="contained" onClick={start}>Start Tournament</Button>
            </Grid2>

        </Grid2>

    </>
}

export default GeneratePlayers;