import { Grid, Typography, Box, Card, CardContent } from "@mui/material";
import { useEffect, useRef, useState } from "react";

let data = [
  {
    name: "Evaluating Threat Modeling Tools: Microsoft TMT versus OWASP Threat Dragon",
    website: "https://ieeexplore.ieee.org/document/9478215",
    abstract: "Threat modeling is an invaluable part of secure software development. However the use of threat modeling tools has not been well documented, even though they are an important asset. In this paper we examine and compare the two prominent threat modeling tools OWASP Threat Dragon and Microsoft Threat Modeling Tool. We outline core concepts related to threat modeling, namely threat identification approaches, data flow diagrams and the importance of risk analysis."
  },
  {
    name: "Visualizing COVID-19 Data and Search Trends",
    website: "https://uis.brage.unit.no/uis-xmlui/handle/11250/3003907?locale-attribute=en",
    abstract: "Since the COVID-19 pandemic’s start, nations have published their COVID-19 data daily. There are already many sites to visualize this COVID-19 data. Many are tied to a specific nation, or will only give the user data for new confirmed cases and deceased. Few of the sites give an insight into how search trends changed during the pandemic. This project aims to show all of the data available to the user in relevant visualizations. The resulting application succeeds in giving clients more control over visualizations, although some of the tools did not get completed in the allotted time. The result is still a working web application that can give helpful insight."
  }
]



const Articles = () => {
  const [Articles, ] = useState(data);

  const element1Ref = useRef<HTMLDivElement>(null);
  const element2Ref = useRef<HTMLDivElement>(null);

  const Refs = [element1Ref, element2Ref];

  useEffect(() => {
    // Set up the IntersectionObserver
    const observer = new IntersectionObserver((entries) => {
      // Loop through each intersecting element
      let timeBetween = 500
      let currentTime = 0
      for (const entry of entries) {
        // Check if the element is intersecting with the viewport
        if (entry.isIntersecting) {
          // If it is, add the "is-visible" class to the element
          setTimeout(() => { entry.target.classList.add('is-visible'); }, currentTime);
          currentTime += timeBetween;

          // Then disconnect the observer for that element so that it only runs once
          observer.unobserve(entry.target);
        }
      }
    });

    // Start observing each element
    observer.observe(element1Ref.current!);
    observer.observe(element2Ref.current!);

    // Clean up the observer when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, []); // This empty array ensures that the effect only runs once

  return (
    <Box sx={{ marginTop: { xs: 5, md: 10 } }} >
      <Typography variant="h4">Published Articles:</Typography>
      <Grid container>
        {Articles.map((p, i) =>
          <Grid key={i} item ref={Refs[i]} className="fade-in" xs={12} md={6} sx={{ marginTop: { xs: 2, md: 5 } }}>
            <Card sx={{ width: "100%", height: "100%" }}>
              <CardContent>
                {p.website ?
                  <a href={p.website}><Typography variant="h5">{p.name}</Typography></a>
                  :
                  <Typography variant="h5">{p.name}</Typography>
                }
                <Typography marginTop={1} variant="body1">{p.abstract}</Typography>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </Box>
  )
};

export default Articles;