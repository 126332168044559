import { Box, Typography } from "@mui/material";
import Articles from "../Components/Articles";
import Bio from "../Components/Bio";
import Experiences from "../Components/Experiences";
import Projects from "../Components/Projects";
import { Voluntary } from "../Components/Voluntary";

const MARGIN = {
    TOP: 2
}

const MainPage = () => {
    return (
        <Box id={"main"} sx={{width: {xs: "95%", md: "90%"}, height: "100%", marginX: "auto", marginTop: MARGIN.TOP }}>
            <div className="container">
                <Typography variant="h2" className="typed">Welcome To My Website</Typography>
            </div>
            <Bio />
            <Experiences />
            <Voluntary />
            <Projects />
            <Articles />
        </Box>
    )
}

export default MainPage;