import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";


import { collection, getDocs } from "firebase/firestore";


// const querySnapshot = await getDocs(collection(db, "users"));
// querySnapshot.forEach((doc) => {
//   console.log(`${doc.id} => ${doc.data()}`);
// });

// const firestore = firebase.firestore();

// Import the functions you need from the SDKs you need
import axios from "axios";
import { useEffect, useState } from "react";
import Project, { ProjectType } from "../Components/Project";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyB0czyQWhF7JIinN_XcFsDCARz4ladcd5k",
    authDomain: "agile-fortress-338814.firebaseapp.com",
    projectId: "agile-fortress-338814",
    storageBucket: "agile-fortress-338814.appspot.com",
    messagingSenderId: "937696131875",
    appId: "1:937696131875:web:dde12f59cbd6e57fc12a96",
    measurementId: "G-0HY5EGMNCQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

const db = getFirestore(app);


async function getProjects() {
    const querySnapshot = await getDocs(collection(db, "Projects"));
    let Projects: any[] = [];
    querySnapshot.forEach((doc) => {
        Projects.push(doc.data())
    });
    return Projects;
}

async function getGithubProjects() {
    let request = await axios.get('https://api.github.com/users/stibreant/repos')
    return (request.data)
}

const ProjectPage = () => {
    const [projects, setProjects] = useState<ProjectType[]>([]);

    useEffect(() => {
        getProjects().then((p) => getGithubProjects().then(p2 => setProjects([...p2, ...p])))
    }
        , [])

    return <>
        <Box id={"main"} sx={{ width: { sx: "100%", md: "80%" }, minHeight: "100%", margin: "auto"}}>
            <Typography variant="h2">These are some of my recent project</Typography>
            <Typography variant="subtitle2">*Note this list may not be upto date and doesn't show projects I have worked on in my proffesional career. </Typography>
            {projects.length !== 0 ?
                <Grid container spacing={2} minHeight="80px" sx={{marginTop: 2, px: 2}} justifyContent={"center"}>
                    {projects.map((p, i) =>
                        <Grid key={i} xs={12} md={4} item>
                            <Project key={i} project={p} fadeDelay={2500 * (i + 1)} />
                        </Grid>

                    )}
                </Grid>
                :
                <Box marginTop={1}><CircularProgress color="secondary"/></Box>
            }
        </Box>
    </>
}

export default ProjectPage;