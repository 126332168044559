import { Box, Typography } from "@mui/material"
import { useState } from "react";
import GeneratePlayers from "../Components/Tournament/GeneratePlayers";
import { Player } from "../Components/Tournament/Player";
import Tournament from "../Components/Tournament/Tournament";


const TournamentPage = () => {
    const [players, setPlayers] = useState<Player[]>([])
    const [gameState, setGameState] = useState<string>("generate")

    function updatePlayers(players: Player[]) {
        setPlayers(players)
    }

    function updateGameState(state: string) {
        setGameState(state)
    }

    return <Box id={"main"} sx={{ width: { sx: "100%", md: "80%" }, minHeight: "100%", margin: "auto" }}>
        <Typography variant={"h2"}>This is the tournament page</Typography>
        {gameState === "generate"? 
        <GeneratePlayers updatePlayers={updatePlayers} updateGameState={updateGameState} />
        :
        <></>
        }
        {gameState === "start" && players.length !== 0? 
        <Tournament players={players}/>
        :
        <></>
        }
    </Box>
}

export default TournamentPage;