import { Box } from "@mui/material";
import Bio from "../Components/Bio"
import Experiences from "../Components/Experiences";

const MARGIN = {
    TOP: 40
}

const AboutPage = () => {
    return <Box id={"main"} sx={{ width: { sx: "80%", md: "80%" }, height: "100%", margin: "auto", paddingTop: MARGIN.TOP, padding: "10px" }}>
        <Bio />
        <Experiences />
    </Box>
}

export default AboutPage