import { Card, Typography } from "@mui/material";
import Grid2 from '@mui/material/Unstable_Grid2';
import { Player } from "./Player";

interface MatchProps {
    match: [Player, Player]
    result: number | null,
    index: number,
    updateResult: (result: number | null, index: number) => void,
}

const Match = (props: MatchProps) => {

    const changeResult = (result: number) => {
        let newResult: number | null = result
        if (props.result === result){
            newResult = null;
        }
        props.updateResult(newResult, props.index)
    }

    return <>
        <Card key={props.index} sx={{ mx: 1 }}>
            <Grid2 container>
                <Grid2 md={2}>
                    <Typography variant="h6" className="choose-result" onClick={() => changeResult(1)}>{props.match[0].name}</Typography>
                </Grid2>
                <Grid2 md ={2}>
                    <Typography variant="h6" className="choose-result" onClick={() => changeResult(0)}>VS</Typography>
                </Grid2>
                <Grid2 md={2}>
                    <Typography variant="h6" className="choose-result" onClick={() => changeResult(-1)}>{props.match[1].name}</Typography>
                </Grid2>
                <Grid2 md>
                    {props.result !== null ?
                        <Typography variant="h6">{`${props.result === 0 ? 0.5 : props.result === 1 ? 1 : 0} : ${props.result === 0 ? 0.5 : props.result === -1 ? 1 : 0}`}</Typography>
                        :
                        <></>
                    }
                </Grid2>
            </Grid2>
        </Card>
    </>
}

export default Match;