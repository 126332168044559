import { Box, Card, Typography } from "@mui/material";
import { useEffect, useRef } from "react";

export type ProjectType = {
    name: string,
    description: string,
    html_url: string,
}

interface ProjectProps {
    project: ProjectType,
    fadeDelay?: number | undefined
}

const Project = (props: ProjectProps) => {
    const ref = useRef<HTMLDivElement>(null);

    // Create a Fade-in Effect
    useEffect(() => {
        if (ref.current != null) {
            // Set up the IntersectionObserver
            const observer = new IntersectionObserver((entries) => {
                // Loop through each intersecting element
                let fadeDelay = 250
                if (props.fadeDelay) {
                    fadeDelay = props.fadeDelay
                }


                for (const entry of entries) {
                    // Check if the element is intersecting with the viewport
                    if (entry.isIntersecting) {
                        // If it is, add the "is-visible" class to the element
                        setTimeout(() => { entry.target.classList.add('is-visible'); }, fadeDelay);

                        // Then disconnect the observer for that element so that it only runs once
                        observer.unobserve(entry.target);
                    }
                }
            });

            // Start observing each element
            observer.observe(ref.current!);

            // Clean up the observer when the component unmounts
            return () => {
                observer.disconnect();
            }
        };
    }, [props.project, props.fadeDelay]); // This empty array ensures that the effect only runs once


    return (
        <Card ref={ref} sx={{ height: "100%" }} variant="elevation">
            <a href={props.project.html_url}><Typography variant="h5">{props.project.name}</Typography></a>

            <Box sx={{ mx: 2 }}>
                {props.project.description ?
                    <Typography>{props.project.description}</Typography>
                    :
                    <Typography>This project did not have any description</Typography>
                }
            </Box>
        </Card>
    )
};

export default Project;