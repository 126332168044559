import { Player } from "./Player";

export class RoundGenerator{
    players: Player[];

   constructor(players:Player[]) { 
      this.players = players 
   }

   randomMatches(): [Player, Player][]{
    let remainingPlayers = [...this.players];
    let matches: [Player, Player][] = [];

    while (remainingPlayers.length > 1) {
        let player1 = remainingPlayers.splice(0, 1)[0];
        let indexPlayer2 = Math.floor(Math.random() * remainingPlayers.length);
        let player2 = remainingPlayers.splice(indexPlayer2, 1)[0];

        if (Math.random() > 0.5){
            matches.push([player1, player2])
        }
        else{
            matches.push([player2, player1])
        }
    }
    return matches
   }

   nextRound(): [Player, Player][]{
    let remainingPlayers = [...this.players];
    let matches: [Player, Player][] = [];

    while (remainingPlayers.length > 1) {
        let player1 = remainingPlayers.splice(0, 1)[0];
        let indexPlayer2 = Math.floor(Math.random() * remainingPlayers.length);
        let player2 = remainingPlayers.splice(indexPlayer2, 1)[0];

        if (Math.random() > 0.5){
            matches.push([player1, player2])
        }
        else{
            matches.push([player2, player1])
        }
    }
    // matches.map((v) => console.log(`${v[0].name}, ${v[1].name}`))

    return matches
   }

    
   disp():string { 
      return("Name is  :   ");
   } 
}