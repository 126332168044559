import { Box, Card, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { useEffect, useRef, useState } from "react";

export type ExperienceType = {
    name: string,
    projectTitle?: string,
    position?: string,
    date: Date,
    endDate?: Date | undefined,
    description: JSX.Element,
    image: string,
    imageBorderRadius?: number
    imageMaxDimensions?: {width: number, height: number},
    html_url: string | undefined,
    technologies?: string[]
}

interface ExperienceProps {
    project: ExperienceType,
    fadeDelay?: number | undefined
    expanded?: boolean
}

const Experience = (props: ExperienceProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        if (props.expanded !== undefined) {
            setExpanded(props.expanded)
        }
    }, [props.expanded])

    // Create a Fade-in Effect
    useEffect(() => {
        if (ref.current != null) {
            // Set up the IntersectionObserver
            const observer = new IntersectionObserver((entries) => {
                // Loop through each intersecting element
                let fadeDelay = 250
                if (props.fadeDelay) {
                    fadeDelay = props.fadeDelay
                }


                for (const entry of entries) {
                    // Check if the element is intersecting with the viewport
                    if (entry.isIntersecting) {
                        // If it is, add the "is-visible" class to the element
                        setTimeout(() => { entry.target.classList.add('is-visible'); }, fadeDelay);

                        // Then disconnect the observer for that element so that it only runs once
                        observer.unobserve(entry.target);
                    }
                }
            });

            // Start observing each element
            observer.observe(ref.current!);
            // Clean up the observer when the component unmounts
            return () => {
                observer.disconnect();
            }
        };
    }, [props.project]); // This empty array ensures that the effect only runs once


    const displayDate = (date: Date, endDate: Date | undefined) => {
        let suffix = endDate ? `- ${endDate.getFullYear()}.${(endDate.getMonth() + 1) < 10 ? "0" + (endDate.getMonth() + 1) : (endDate.getMonth() + 1)} ` : " - Present"
        return `${date.getFullYear()}.${(date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1)} ${suffix} `
    }

    return (
        <Grid2 style={{ marginTop: 50, marginBottom: 30 }} container alignItems={"center"}>
            <Grid2 xs={12} md={8} justifyContent={"center"} alignItems={"center"} onClick={(e) => {setExpanded(!expanded)}} container style={{cursor: "pointer"}}>
                <Card ref={ref} sx={{ height: "100%" }} variant="elevation">
                    <Typography variant="h4">{props.project.name}, {props.project.position}</Typography>

                    <Typography variant="h5">{props.project.projectTitle? props.project.projectTitle + "," : ""} {displayDate(props.project.date, props.project.endDate)}</Typography>

                    {expanded ? <Box sx={{ mx: 2 }}>
                        {props.project.description ?
                            <Typography>{props.project.description}</Typography>
                            :
                            <Typography>This project did not have any description</Typography>
                        }
                    </Box> : <Typography variant="h6">...</Typography>}
                    {props.project.technologies ? 
                    <Typography fontSize={14} marginTop={1}>Technologies: {props.project.technologies.join(", ")}</Typography>
                    : 
                    <></>}
                </Card>
            </Grid2>

            <Grid2 xs={6} md={4} sx={{ marginTop: { xs: 5, md: 0 }, marginX: { xs: "auto", md: 0} }}>
                <a href={props.project.html_url}>
                    <img alt={props.project.name} src={props.project.image} style={{ maxHeight: `${props.project.imageMaxDimensions ? props.project.imageMaxDimensions.height : 125}px`, maxWidth: `${props.project.imageMaxDimensions ? props.project.imageMaxDimensions.width : 200}px` ,width: "auto", borderRadius: props.project.imageBorderRadius ? props.project.imageBorderRadius : 0}}/>
                </a>
            </Grid2>
        </Grid2>
    )
};

export default Experience;